import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { allGlossaireType } from "../utils/model"

type GlossairePageProps = {
  data: {
    glossaire: allGlossaireType
  }
}

function GlossairePage(props: GlossairePageProps) {
  const glossaire = props.data?.glossaire.nodes

  useEffect(() => {
    document.body.className = "page-glossaire"
  }, [])

  return (
    <Layout contentClassName="container">
      <SEO
        title="Glossaire"
        description=""
        gtmPageType="aide"
        gtmPageName="glossaire"
      />
      <div className="mx-2 py-16 md:mx-auto md:max-w-2xl lg:max-w-3xl text-primary">
        <h1 className="text-3xl md:text-5xl leading-none text-primary font-black mb-8">
          Glossaire
        </h1>

        <div>
        {glossaire.reverse().map(def => (
          <div
            key={def.id}
            className="mb-6 markdown"
          >
            <strong className="block text-lg font-bold font-sans">{def.data.Name}</strong>
            <div dangerouslySetInnerHTML={{ __html: def.data.Notes?.childMarkdownRemark.html }}></div>
          </div>
        ))}
        </div>
      </div>
    </Layout>  )
}

export const query = graphql`
  query GlossaireQuery {
    glossaire: allAirtable(
      filter: { table: { eq: "Glossary" }
    }) {
      nodes {
        id
        data {
          Name
          Notes{
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

export default GlossairePage
